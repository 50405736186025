import React from "react";
import Group10000064703 from "../Group10000064703";
import Frame613 from "../Frame613";
import "./AIProduct.css";

function AIProduct(props) {
  const {
    group1000006558,
    spanText1,
    spanText2,
    line2,
    group1000006406,
    spanText3,
    line785,
    line786,
    line787,
    line788,
    line789,
    line790,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    group10000064703Props,
    frame613Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="ai-product screen">
        <Group10000064703 vector24={group10000064703Props.vector24} />
        <div className="overlap-group6">
          <div className="frame-97-1">
            <img className="group-1000006558" src={group1000006558} alt="Group 1000006558" />
          </div>
          <p className="this-revolutionary-g foundersgrotesk-regular-normal-white-20px">
            <span className="foundersgrotesk-regular-normal-white-20px">The revolutionary AI that effortlessly generates construction-ready architectural blueprints with unparalleled precision and speed.</span>
          </p>
          <div className="group-1000006557">
            <div className="ai-product-1 foundersgrotesk-regular-normal-white-30px">
              <span className="foundersgrotesk-regular-normal-white-30px">{spanText2}</span>
            </div>
          </div>
        </div>
        <div className="overlap-group7-1">
          <div className="group-1000006439">
            <img className="line-2-1" src={line2} alt="Line 2" />
            <img className="group-1000006406-1" src="/img/Step5.jpg" alt="Group 1000006406" />
          </div>
          <p className="weve-trained-a-mode-1 foundersgrotesk-regular-normal-black-19-5px">
            <span className="foundersgrotesk-regular-normal-black-19-5px">The AI product will provide architects with a comprehensive tool to quickly explore, generate, and design blueprints and their potential impact, allowing them to take on more projects, meet deadlines, and provide innovative design solutions.</span>
          </p>
        </div>
        <div className="overlap-group5-1">
          <div className="rectangle-176-1"></div>
          <div className="ellipse-81-1"></div>
          <img className="line-785-1" src={line785} alt="Line 785" />
          <img className="line-786-1" src={line786} alt="Line 786" />
          <div className="rectangle-179-1"></div>
          <img className="line-787-1" src={line787} alt="Line 787" />
          <img className="line-788-1" src={line788} alt="Line 788" />
          <div className="ellipse-82-1"></div>
          <div className="ellipse-83-1"></div>
          <div className="ellipse-86-1"></div>
          <div className="ellipse-87-1"></div>
          <img className="line-789-1" src={line789} alt="Line 789" />
          <div className="ellipse-84-1"></div>
          <div className="ellipse-85-1"></div>
          <img className="line-790-1" src={line790} alt="Line 790" />
          <div className="overlap-group4">
            <h1 className="title-1 foundersgrotesk-regular-normal-white-38px">
              <span className="foundersgrotesk-regular-normal-black-38px">{spanText4}</span>
            </h1>
            <div className="group-1000006588-1">
              <div className="flex-col-1">
                <div className="join-the-waitlist-1 foundersgrotesk-regular-normal-blueberry-27px">
                  <span className="foundersgrotesk-regular-normal-portage-27px">{spanText5}</span>
                  <span className="foundersgrotesk-regular-normal-blueberry-27px-2">{spanText6}</span>
                </div>
                <div className="enter-your-email-address-1 foundersgrotesk-regular-normal-pigeon-post-20px">
                <input
                    type="email"
                    className="email-input foundersgrotesk-regular-normal-pigeon-post-20px"
                    placeholder="email address"
                  />
                  {/* <span className="foundersgrotesk-regular-normal-pigeon-post-20px">{spanText7}</span> */}
                </div>
              </div>
              <div className="overlap-group-9">
                <div className="submit-1 foundersgrotesk-regular-normal-white-20px">
                  <span className="foundersgrotesk-regular-normal-white-20px">{spanText8}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Frame613 group1000006420={frame613Props.group1000006420} className={frame613Props.className} />
      </div>
    </div>
  );
}

export default AIProduct;
