import React from "react";
import "./Frame100.css";

function Frame100(props) {
  const { className } = props;

  return (
    <div className={`frame-100 foundersgrotesk-regular-normal-fuscous-gray-19-5px ${className || ""}`}>
      <div className="reduce-peak-demand">
        <span className="span-3 foundersgrotesk-regular-normal-fuscous-gray-19-5px">Improve Climate within Building</span>
      </div>
      <div className="ting">
        <span className="span-3 foundersgrotesk-regular-normal-fuscous-gray-19-5px">Install EV Charging Stations</span>
      </div>
      <div className="ting">
        <span className="span-3 foundersgrotesk-regular-normal-fuscous-gray-19-5px">Consider Pedestrian Walkways</span>
      </div>
    </div>
  );
}

export default Frame100;
