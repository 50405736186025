import React from "react";
import { Link } from 'react-router-dom';
import Group10000064703 from "../Group10000064703";

function Calculator(props) {
  const {
    group10000064703Props,
  } = props;

  const calculatorStyles = {
    background: "black",
    textAlign: "left",
    minHeight: "100vh"
  };

  const questionCardStyles = {
    color: "white",
    fontSize: "20px",
    margin: "50px 150px",
    padding: "16px",
  };

  const liStyles = {
    padding: "15px",
    marginBottom: "10px",
    border: "3px solid white",
    borderRadius: "15px",
    width: "60%",
  };

  const h2Styles = {
    fontSize: "25px",
    marginBottom: "20px",
  };

  return (
    <div style={calculatorStyles}>
      {/* Your navbar component */}
      <Group10000064703 {...group10000064703Props} />

      {/* The rest of your calculator component */}
      {/* Add your calculator content here */}
      <div style={questionCardStyles}>
        <h2 style={h2Styles}>Question 1: Is my name Jake?</h2>

        <ul>
          <li style={liStyles}>Yes</li>
          <li style={liStyles}>No</li>
          <li style={liStyles}>Could be</li>
          <li style={liStyles}>Idk</li>
        </ul>
      </div>
    </div>
  );
}

export default Calculator;
