import React from "react";
import "./Group1000006592.css";

function Group1000006592(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`group-100000659-1 ${className || ""}`}>
      <div className="name foundersgrotesk-regular-normal-white-21-8px">
        <span className="span-10 foundersgrotesk-regular-normal-white-21-8px">{spanText1}</span>
      </div>
      <div className="developer foundersgrotesk-medium-white-21-8px">
        <span className="span-10 foundersgrotesk-medium-white-21-8px">{spanText2}</span>
      </div>
    </div>
  );
}

export default Group1000006592;
