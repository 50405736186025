import React from "react";
import "./Group1000006471.css";

function Group1000006471() {
  return (
    <div className="group-1000006471">
      <div className="energy-consumption-calculator foundersgrotesk-regular-normal-white-26px">
        <span className="foundersgrotesk-regular-normal-white-26px">Know Your Numbers, Know Your Impact</span>
      </div>
      <img className="line-15" src="/img/line-15-1@2x.png" alt="Line 15" />
      <p className="construction-account foundersgrotesk-regular-normal-white-22px">
        <span className="foundersgrotesk-regular-normal-white-22px">
        Our goal is to create more sustainable buildings, from planning to construction. With our calculator, you can see how every building you create affects the Earth’s carbon footprint and total emissions. 

        </span>
      </p>
    </div>
  );
}

export default Group1000006471;
