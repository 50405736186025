import React, { useState } from "react";
import "./SignInPage.css"
import Group10000064703 from "../Group10000064703";
import Frame613 from "../Frame613";
import Group1000006462 from "../Group1000006462";

function SignIn(props) {
    const [emailTerm, setEmailTerm] = useState('');
    const [emailError, setEmailError] = useState('');
    const {
        frame613Props
    } = props;
    const [email, setEmail] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        // if(email.trim().length >= 0) {
        //     setEmailError('Success');
        //     // return;
        // }
        // setEmailError('');
        console.log(email); // or send the email to your backend
        const url = 'https://btbackend-383017.wl.r.appspot.com/api/email/add?email=' + email;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Set content type to form-urlencoded
            },
        })
        .then(response => {
            if (!response.ok) {
                setEmailError('Email already exists!')
                // Send already registered here
            } else {
                setEmailError('Success!');
                // Send successfully registered
            }
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
          });
    };


    return (
        <div className = "superior">
        <div>
            <Group10000064703 vector24="/img/vector-24-2@2x.png" />
        <div className="overlap-group19">
            <div className="rectangle-176"></div>
            <div className="ellipse-81"></div>
            <img
                className="line-785"
                src="/img/line-785-1@2x.png"
                alt="Line 785"
            />
            <img
                className="line-786"
                src="/img/line-786-1@2x.png"
                alt="Line 786"
            />
            <div className="rectangle-179"></div>
            <img
                className="line-787"
                src="/img/line-787-1@2x.png"
                alt="Line 787"
            />
            <img
                className="line-788"
                src="/img/line-788-1@2x.png"
                alt="Line 788"
            />
            <div className="ellipse-82"></div>
            <div className="ellipse-83"></div>
            <div className="ellipse-86"></div>
            <div className="ellipse-87"></div>
            <img
                className="line-789"
                src="/img/line-789-1@2x.png"
                alt="Line 789"
            />
            <div className="ellipse-84"></div>
            <div className="ellipse-85"></div>
            <img
                className="line-790"
                src="/img/line-790-1@2x.png"
                alt="Line 790"
            />
            <div className="overlap-group17">
                <div className="want-to-know-more foundersgrotesk-regular-normal-white-38px">
          <span className="foundersgrotesk-regular-normal-black-38px">
            Want to know more?
          </span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="group-1000006588">
                        <div className="flex-col">
                            <div className="join-the-waitlist foundersgrotesk-regular-normal-blueberry-27px">
                <span className="foundersgrotesk-regular-normal-portage-27px">
                  <br />
                </span>
                                <span className="foundersgrotesk-regular-normal-blueberry-27px-2">
                  Join the waitlist.
                </span>
                            </div>
                            <div className="enter-your-email-address foundersgrotesk-regular-normal-pigeon-post-20px">
                                <input
                                    type="email"
                                    className="email-input foundersgrotesk-regular-normal-pigeon-post-20px"
                                    placeholder="email address"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    required
                                />
                                <small id="search-error" className="form-text text-danger" style={{ color: 'green', fontWeight: 'bold' }}>
                                    {emailError}
                                </small>
                            </div>
                        </div>
                        <div className="overlap-group-2">
                            <button type="submit" className="submit curry foundersgrotesk-regular-normal-white-20px">
                <span className="foundersgrotesk-regular-normal-white-20px">
                  submit
                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
                <div className="group-container-2">
            <Frame613 group1000006420="/img/group-1000006420-2@2x.png" className=""/>
                </div>
           </div>

        </div>
    );
}

export default SignIn;



