import React, {useEffect, useState} from "react";
import Group1000006470 from "../Group1000006470";
import Group1000006471 from "../Group1000006471";
import Group1000006549 from "../Group1000006549";
import Group1000006592 from "../Group1000006592";
import Frame61 from "../Frame61";
import "./Landing.css";
import { Link } from 'react-router-dom';



function Landing(props) {
  const {
    mesh21,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    arrow1,
    spanText6,
    arrow2,
    vector24,
    rectangle95,
    vector25,
    vector23,
    group1000006420,
    spanText7,
    spanText8,
    vector34,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    ellipse65,
    vector38,
    vector39,
    vector40,
    vector41,
    vector42,
    group1000006580,
    group1000006581,
    group1000006582,
    group1000006583,
    line758,
    line760,
    line761,
    line762,
    line763,
    line764,
    line765,
    line766,
    line767,
    line768,
    line769,
    line771,
    line770,
    line759,
    spanText15,
    vector43,
    group1000006478,
    spanText16,
    spanText17,
    spanText18,
    spanText19,
    subtract1,
    subtract2,
    spanText20,
    spanText21,
    group1000006547,
    spanText22,
    spanText23,
    line735,
    spanText24,
    group9,
    group1000006470Props,
    group10000065921Props,
    group10000065922Props,
    group10000065923Props,
    group10000065924Props,
    group10000065925Props,
    group10000065926Props,
    group10000065927Props,
    group10000065928Props,
    group10000065929Props,
    group100000659210Props,
    group100000659211Props,
    group100000659212Props,
    group100000659213Props,
    group100000659214Props,
    group100000659215Props,
    group100000659216Props,
    group100000659217Props,
    group100000659218Props,
    frame61Props,
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    document.title = 'BT.AI';
  }, []);

  return (
    <div className="container-center-horizontal">
      <div className="landing screen">
        <div className="overlap-group23-1">
          <img className="mesh2-1" src={mesh21} alt="mesh2 1" />
          <div className="rectangle-67"></div>
          <h1 className="title-2 foundersgrotesk-regular-normal-ship-gray-71px">
            <span className="foundersgrotesk-regular-normal-ship-gray-71px">{spanText1}</span>
            <span className="span1">{spanText2}</span>
            <span className="foundersgrotesk-regular-normal-ship-gray-71px">{spanText3}</span>
          </h1>
          <p className="an-ai-solution-to-ma-2 foundersgrotesk-light-ship-gray-23-4px">
            <span className="foundersgrotesk-light-ship-gray-23-4px">{spanText4}</span>
          </p>
          <div className="overlap-group4-1">
            <div className="join-the-beta foundersgrotesk-regular-normal-white-16-2px">
            <Link to="/sign-up" className="link-class foundersgrotesk-regular-normal-white-16-2px">
                {spanText5}
              </Link> 
              {/* <span className="foundersgrotesk-regular-normal-white-16-2px">{spanText5}</span> */}
            </div>
            <img className="arrow-1" src={arrow1} alt="Arrow 1" />
          </div>
          <div className="group-1000006551"></div>
          <div className="group-1000006576">
            <div className="learn-more foundersgrotesk-regular-normal-ship-gray-16-2px">
            <Link to="/sign-up" className="foundersgrotesk-regular-normal-white-16-2px">
                {spanText6}
              </Link>
              {/* <span className="foundersgrotesk-regular-normal-ship-gray-16-2px">{spanText6}</span> */}
            </div>
            <img className="arrow-2" src={arrow2} alt="Arrow 2" />
          </div>
          <div className="overlap-group8-1 foundersgrotesk-regular-normal-ship-gray-18px">
          <Link to="/landing">
            <div className="group-container-6">
              <div className="overlap-group-11">
                <img className="vector-24-4" src={vector24} alt="Vector 24" />
                <img className="rectangle-95-4" src={rectangle95} alt="Rectangle 95" />
                <img className="vector-25-4" src={vector25} alt="Vector 25" />
                <img className="vector-23-4" src={vector23} alt="Vector 23" />
                <div className="rectangle-96-4"></div>
              </div>
              <img className="group-1000006420-4" src={group1000006420} alt="Group 1000006420" />
            </div>
            </Link>
            <div className="company-2">
              <Link to="/landing" className="foundersgrotesk-regular-normal-ship-gray-18px">
               {spanText7}
              </Link>            
            </div>
            <div className="group-1000006484-2">
            <div className="product" onClick={toggleDropdown}>
                        <span className="foundersgrotesk-regular-normal-ship-gray-18px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Product&nbsp;   </span>
                        <img className="vector-34" src="/img/vector-34-1@2x.png" alt="Vector 34" />
                        {showDropdown && (
                            <div className="dropdown">
                                <ul>
                                    <li>
                                        <Link to ="/ai-product">AI Product</Link>

                                    </li>
                                    <li>
                                        <Link to ="/leeds-calculator">Green</Link>

                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
              {/* <img className="vector-34-2" src={vector34} alt="Vector 34" /> */}
            </div>
            <div className="place-2">
            <Link to="/sign-up" className="foundersgrotesk-regular-normal-ship-gray-18px">
                Research
              </Link> 
            </div>
            <div className="frame-11-2">
              <div className="sign-up-2 foundersgrotesk-regular-normal-ship-gray-18px">
              <Link to="/sign-up" className="foundersgrotesk-regular-normal-ship-gray-18px">
                {spanText10}
              </Link> 
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-group-container-3">
          <div className="overlap-group2-3">
            <div className="overlap-group-10">
              <div className="explore-more foundersgrotesk-regular-normal-ship-gray-20px">
              <Link to="/leeds-calculator" className="foundersgrotesk-regular-normal-ship-gray-20px">
               {spanText11}
              </Link> 
                {/* <span className="foundersgrotesk-regular-normal-ship-gray-20px">{spanText11}</span> */}
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="rectangle-1"></div>
            <Group1000006470
              group1000006459={group1000006470Props.group1000006459}
              group10000064621Props={group1000006470Props.group10000064621Props}
              group10000064622Props={group1000006470Props.group10000064622Props}
              group10000064623Props={group1000006470Props.group10000064623Props}
              group10000064624Props={group1000006470Props.group10000064624Props}
            />
            <div className="energy-savings foundersgrotesk-regular-normal-turquoise-blue-41px">
              <span className="foundersgrotesk-regular-normal-turquoise-blue-41px">Energy Consumption Calculator</span>
            </div>
            <Group1000006471 />
          </div>
          <div className="overlap-group2-3">
            <Group1000006549 />
            <div className="rectangle25"></div>
            <div className="overlap-group-10">
              <div className="explore-more foundersgrotesk-regular-normal-ship-gray-20px">
              <Link to="/ai-product" className="foundersgrotesk-regular-normal-ship-gray-20px">
               {spanText13}
              </Link> 
                {/* <span className="foundersgrotesk-regular-normal-ship-gray-20px">{spanText13}</span> */}
              </div>
            </div>
            <div className="rectangle-1"></div>
            <div className="design-accessibility foundersgrotesk-regular-normal-turquoise-blue-41px">
              <span className="foundersgrotesk-regular-normal-turquoise-blue-41px">{spanText14}</span>
            </div>
            <div className="overlap-group21-1">
              <div className="group-1000006585">
                <div className="overlap-group-12">
                  <div className="ellipse-62"></div>
                  <img className="ellipse-65" src={ellipse65} alt="Ellipse 65" />
                  <div className="ellipse-63"></div>
                  <div className="ellipse-64"></div>
                  <img className="vector-38" src={vector38} alt="Vector 38" />
                  <img className="vector-39" src={vector39} alt="Vector 39" />
                  <img className="vector-40" src={vector40} alt="Vector 40" />
                  <img className="vector-41" src={vector41} alt="Vector 41" />
                  <img className="vector-42" src={vector42} alt="Vector 42" />
                  <img className="group-1000006580-1" src={group1000006580} alt="Group 1000006580" />
                  <img className="group-1000006581" src={group1000006581} alt="Group 1000006581" />
                  <img className="group-1000006582" src={group1000006582} alt="Group 1000006582" />
                  <img className="group-1000006583" src={group1000006583} alt="Group 1000006583" />
                  <img className="line-758" src={line758} alt="Line 758" />
                  <img className="line-760" src={line760} alt="Line 760" />
                  <img className="line-761" src={line761} alt="Line 761" />
                  <img className="line-762" src={line762} alt="Line 762" />
                  <img className="line-763" src={line763} alt="Line 763" />
                  <img className="line-764" src={line764} alt="Line 764" />
                  <img className="line-765" src={line765} alt="Line 765" />
                  <img className="line-766" src={line766} alt="Line 766" />
                  <img className="line-767" src={line767} alt="Line 767" />
                  <img className="line-768" src={line768} alt="Line 768" />
                  <img className="line-769" src={line769} alt="Line 769" />
                  <img className="line-771" src={line771} alt="Line 771" />
                  <img className="line-770" src={line770} alt="Line 770" />
                </div>
                <div className="overlap-group1-2">
                  <div className="ellipse-66"></div>
                  <div className="ellipse-67"></div>
                  <img className="line-759" src={line759} alt="Line 759" />
                  <div className="number f25bankprinter-regular-normal-blueberry-10px">
                    <span className="f25bankprinter-regular-normal-blueberry-10px">{spanText15}</span>
                  </div>
                </div>
              </div>
              <div className="ellipse-68"></div>
              <img className="vector-43" src={vector43} alt="Vector 43" />
              <div className="ellipse-69"></div>
            </div>
          </div>
        </div>
        <div className="group-container-7">
          <div className="overlap-group3-1">
            <div className="overlap-group-13">
              <img className="group-1000006478" src={group1000006478} alt="Group 1000006478" />
            </div>
            <p className="save-millions-of-dol foundersgrotesk-regular-normal-persian-blue-19px">
              <span className="foundersgrotesk-regular-normal-persian-blue-19px">Revolutionizing the architectural design process</span>
            </p>
            {/* <div className="learn-more-1 foundersgrotesk-medium-persian-blue-19px">
            <Link to="/sign-up" className="link-class1 foundersgrotesk-medium-persian-blue-19px">
               {spanText17}
              </Link> 
              {/* <span className="foundersgrotesk-medium-persian-blue-19px">{spanText17}</span> */}
            
          </div>
          <div className="group-100000648">
            <div className="group-1000006545">
              <div className="rectangle-155"></div>
              <img className="vector" src="/img/vector-1@2x.png" alt="Vector" />
            </div>
            <p className="btai-has-saved-two foundersgrotesk-regular-normal-persian-blue-19px">
              <span className="foundersgrotesk-regular-normal-persian-blue-19px">Minimizing environmental impact</span>
            </p>
            <p className="heres-how-we-did-it foundersgrotesk-medium-persian-blue-19px">
            {/* <Link to="/sign-up" className="link-class1 foundersgrotesk-medium-persian-blue-19px">
               {spanText19}
              </Link>  */}
              {/* <span className="foundersgrotesk-medium-persian-blue-19px">{spanText19}</span> */}
            </p>
          </div>
          <div className="group-100000648">
            <div className="group-1000006543">
              <div className="subtract-container-1">
                <img className="subtract-3" src={subtract1} alt="Subtract" />
                <img className="subtract-4" src={subtract2} alt="Subtract" />
              </div>
            </div>
            <p className="take-a-glance-into-o foundersgrotesk-regular-normal-persian-blue-19px">
              <span className="foundersgrotesk-regular-normal-persian-blue-19px">Advancing the state of the art</span>
            </p>
            <div className="take-a-peek foundersgrotesk-medium-persian-blue-19px">
            {/* <Link to="/sign-up" className="link-class1 foundersgrotesk-medium-persian-blue-19px">
                {spanText21}
              </Link>  */}
              {/* <span className="foundersgrotesk-medium-persian-blue-19px">{spanText21}</span> */}
            </div>
          </div>
        </div>
        <div className="group-1000006577">
          <div className="group-container-8">
            <div className="group-1000006547" style={{ backgroundImage: `url(${group1000006547})` }}>
              <div className="we-a-container">
                <div className="who-we-are foundersgrotesk-regular-normal-white-39-4px">
                  <span className="foundersgrotesk-regular-normal-white-39-4px">{spanText22}</span>
                </div>
                <p className="founded-at-usc-we-a foundersgrotesk-regular-normal-white-20-4px">
                  <span className="foundersgrotesk-regular-normal-white-20-4px">{spanText23}</span>
                </p>
              </div>
              <img className="line-735" src={line735} alt="Line 735" />
              <div className="overlap-group-14">
                <div className="meet-the-team foundersgrotesk-regular-normal-white-29-4px">
                  <span className="foundersgrotesk-regular-normal-white-29-4px">{spanText24}</span>
                </div>
                <div className="rectangle-62"></div>
              </div>
            </div>
            <div className="parent">
            <div className="group-container-9">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065921Props.spanText1}
                spanText2={group10000065921Props.spanText2}
              />
            </div>
            <div className="group-container-15">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065927Props.spanText1}
                spanText2={group10000065927Props.spanText2}
                className={group10000065927Props.className}
              />
            </div>
            <div className="group-container-17">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065929Props.spanText1}
                spanText2={group10000065929Props.spanText2}
                className={group10000065929Props.className}
              />
            </div>
            <div className="group-container-21">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659213Props.spanText1}
                spanText2={group100000659213Props.spanText2}
              />
            </div>
            </div>
            <div className="group-container-112">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065922Props.spanText1}
                spanText2={group10000065922Props.spanText2}
              />
            </div>
            <div className="group-container-11">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065923Props.spanText1}
                spanText2={group10000065923Props.spanText2}
              />
            </div>
            <div className="group-container-132">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065924Props.spanText1}
                spanText2={group10000065924Props.spanText2}
              />
            </div>
            <div className="group-container-13">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065925Props.spanText1}
                spanText2={group10000065925Props.spanText2}
              />
            </div>
            <div className="group-container-14">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065926Props.spanText1}
                spanText2={group10000065926Props.spanText2}
              />
            </div>
            
            <div className="group-container-16">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group10000065928Props.spanText1}
                spanText2={group10000065928Props.spanText2}
                className={group10000065928Props.className}
              />
            </div>
            
            <div className="group-container-18">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659210Props.spanText1}
                spanText2={group100000659210Props.spanText2}
                className={group100000659210Props.className}
              />
            </div>
            <div className="group-container-19">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659211Props.spanText1}
                spanText2={group100000659211Props.spanText2}
                className={group100000659211Props.className}
              />
            </div>
            <div className="group-container-20">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659212Props.spanText1}
                spanText2={group100000659212Props.spanText2}
                className={group100000659212Props.className}
              />
            </div>

            <div className="group-container-22">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659214Props.spanText1}
                spanText2={group100000659214Props.spanText2}
              />
            </div>
            <div className="group-container-23">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659215Props.spanText1}
                spanText2={group100000659215Props.spanText2}
              />
            </div>
            <div className="group-container-24">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659216Props.spanText1}
                spanText2={group100000659216Props.spanText2}
              />
            </div>
            <div className="group-container-25">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659217Props.spanText1}
                spanText2={group100000659217Props.spanText2}
              />
            </div>
            <div className="group-container-26">
              <div className="group-container-5">
                <div className="group-1000006578"></div>
                <div className="group-1000006580"></div>
              </div>
              <Group1000006592
                spanText1={group100000659218Props.spanText1}
                spanText2={group100000659218Props.spanText2}
              />
            </div>
          </div>
        </div>
        <Frame61 group1000006420={frame61Props.group1000006420} spanText7={frame61Props.spanText7} />
        {/* <img className="group-9" src={group9} alt="Group 9" /> */}
      </div>
    </div>
  );
}

export default Landing;
