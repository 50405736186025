import React from "react";
import { Link } from 'react-router-dom';
import Group10000064703 from "../Group10000064703";
import Group1000006440 from "../Group1000006440";
import Frame100 from "../Frame100";
import Frame613 from "../Frame613";
import "./LEEDSCalculator.css";
import Circle from "./Circle";

function LEEDSCalculator(props) { 
  const {
    overContainer,
    spanText1,
    spanText2,
    vector17,
    spanText3,
    spanText4,
    polygon1,
    spanText5,
    polygon2,
    overlapGroup8,
    spanText6,
    vector311,
    spanText7,
    vector11,
    spanText8,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    line70,
    line71,
    vector312,
    group1000006383,
    subtract1,
    subtract2,
    subtract3,
    subtract4,
    spanText15,
    spanText16,
    spanText17,
    spanText18,
    spanText19,
    line785,
    line786,
    line787,
    line788,
    line789,
    line790,
    spanText20,
    spanText21,
    spanText22,
    spanText23,
    spanText24,
    group10000064703Props,
    group1000006440Props,
    frame100Props,
    frame613Props,
    GreenNavbarProps,
    CircleProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="leeds-calculator screen">
        <Group10000064703 vector24={group10000064703Props.vector24} />
        <div className="overlap-group20">
          <div className="frame-97">
            <div className="over-container">
              <div className="overlap-group1">
                <div className="leed-calculator foundersgrotesk-regular-normal-white-30px">
                  <span className="foundersgrotesk-regular-normal-white-30px">{spanText1}</span>
                </div>
              </div>
              <p className="discover-the-power-o foundersgrotesk-regular-normal-white-20px">
                <span className="foundersgrotesk-regular-normal-white-20px">Discover the power of sustainability with our innovative LEED rating calculator, which illuminates the true environmental impact of your building and sets you on the path towards a more energy-efficient future.</span>
              </p>
            </div>
          </div>
        </div>
        <Group1000006440 group1000006406={group1000006440Props.group1000006406} />
        <div className="overlap-group21">
          <Circle  message = "Site" style={{ top: '8%', left: '79%' }}  /> 
          <Circle  message = "Transportation" style={{ top: '20%', left: '50%' }}   /> 
          <Circle  message = "Materials" style={{ top: '20%', left: '108%' }}   /> 
          <Circle  message = "Budget" style={{ top: '45%', left: '30%' }}  /> 
          <Circle  message = "IEQ" style={{ top: '45%', left: '128%' }}   /> 
          <Circle  message = "Water" style={{ top: '80%', left: '23%' }}   /> 
          <Circle  message = "Innovation" style={{ top: '80%', left: '135%' }}  /> 
          <Circle  message = "Energy" style={{ top: '115%', left: '30%', position: 'relative' }}  /> 
          <Circle  message = "Indoor Environmental Quality" style={{ top: '115%', left: '128%' }}  /> 





          {/* previous leed calc mid section */}
          {/* <img className="vector-17" src={vector17} alt="Vector 17" />
          <p className="weve-trained-a-mode foundersgrotesk-regular-normal-black-19-5px">
          <span className="foundersgrotesk-regular-normal-black-19-5px">
          The LEED (Leadership in Energy and Environmental Design) Calculator is an 
          essential feature for architects who are committed to designing 
          sustainable buildings that meet LEED certification standards. This feature will help architects 
          calculate the number of points their building project can earn in various LEED categories, such 
          as energy efficiency, water conservation, and sustainable materials, based on specific design 
          choices. The LEED Calculator will enable architects to make more informed decisions about 
          sustainable design choices and create a more accurate plan for LEED certification from the outset. 
          The feature will also simplify the LEED certification process, allowing architects to quickly identify areas where they can earn more points and optimize their building's design to achieve higher levels of certification. 
          Ultimately, the LEED Calculator will help architects design more sustainable buildings that have a positive impact on the environment, human health, and the economy.
          </span>
          </p>
          <div className="group-1000006483">
            <div className="ellipse-container">
              <div className="ellipse-56"></div>
              <div className="ellipse-57"></div>
            </div>
          </div>
          <div className="rectangle-141"></div>
          <div className="rectangle-152"></div>
          <div className="overlap-group5">
            <div className="waste-management foundersgrotesk-regular-normal-white-20px">
              <span className="foundersgrotesk-regular-normal-white-20px">{spanText3}</span>
            </div>
          </div>
          <div className="rectangle-18"></div>
          <div className="energy-consumption foundersgrotesk-regular-normal-white-20px-2">
            <span className="foundersgrotesk-regular-normal-white-20px-2">{spanText4}</span>
          </div>
          <div className="group-1000006474">
            <div className="ellipse-54"></div>
            <div className="ellipse-container-1">
              <div className="ellipse-49"></div>
              <div className="ellipse-50"></div>
              <div className="ellipse-51"></div>
              <div className="ellipse-52"></div>
              <div className="ellipse-55"></div>
            </div>
            <div className="ellipse-53"></div>
          </div>
          <div className="overlap-group7">
            <img className="polygon-1" src={polygon1} alt="Polygon 1" />
            <div className="water-usage foundersgrotesk-regular-normal-white-20px">
              <span className="foundersgrotesk-regular-normal-white-20px">{spanText5}</span>
            </div>
          </div>
          <img className="polygon-2" src={polygon2} alt="Polygon 2" />
          <div className="overlap-group8" style={{ backgroundImage: `url(${overlapGroup8})` }}>
            <div className="ellipse-container-2">
              <div className="ellipse-56-1"></div>
              <div className="ellipse-57-1"></div>
            </div>
            <div className="carbon-emission foundersgrotesk-regular-normal-white-20px">
              <span className="foundersgrotesk-regular-normal-white-20px">{spanText6}</span>
            </div>
          </div>
          <div className="overlap-group9">
            <div className="rectangle-142"></div>
            <img className="vector-31" src={vector311} alt="Vector 31" />
            <div className="rectangle-141-1"></div>
          </div>
          <div className="leed-calculator-1 foundersgrotesk-regular-normal-blueberry-35px">
            <span className="foundersgrotesk-regular-normal-blueberry-35px">{spanText7}</span>
          </div>
          <div className="rectangle-153"></div>
          <img className="vector-11" src={vector11} alt="Vector 11" />
          <div className="rectangle-156"></div>
          <div className="overlap-group10">
            <div className="energy-bills foundersgrotesk-regular-normal-blue-marguerite-18-4px">
              <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText8}</span>
            </div>
          </div>
          <div className="waste-management-1 foundersgrotesk-regular-normal-blue-marguerite-18-4px">
            <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText9}</span>
          </div>
          <div className="overlap-group11">
            <div className="gas-consumption foundersgrotesk-regular-normal-blue-marguerite-18-4px">
              <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText10}</span>
            </div>
          </div>
          <div className="overlap-group12">
            <div className="functional-unit foundersgrotesk-regular-normal-blue-marguerite-18-4px">
              <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText11}</span>
            </div>
          </div>
          <div className="overlap-group">
            <div className="building-automation foundersgrotesk-regular-normal-blue-marguerite-18-4px">
              <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText12}</span>
            </div>
          </div>
          <div className="overlap-group-1">
            <div className="renewable-energy foundersgrotesk-regular-normal-blue-marguerite-18-4px">
              <span className="foundersgrotesk-regular-normal-blue-marguerite-18-4px">{spanText13}</span>
            </div>
          </div>
          <Link to="/Calculator">
            <div className="rectangle-157"></div>
          
          <div className="create foundersgrotesk-regular-normal-white-24px">
            <span className="foundersgrotesk-regular-normal-white-24px">{spanText14}</span>
          </div>
          </Link>
          <img className="line-70" src={line70} alt="Line 70" />
          <img className="line-71" src={line71} alt="Line 71" />
          <div className="group-1000006475">
            <div className="ellipse-54-1"></div>
            <div className="ellipse-container-3">
              <div className="ellipse-49-1"></div>
              <div className="ellipse-50-1"></div>
              <div className="ellipse-51-1"></div>
              <div className="ellipse-52-1"></div>
              <div className="ellipse-55-1"></div>
            </div>
            <div className="ellipse-53-1"></div>
          </div>
          <div className="overlap-group16">
            <div className="rectangle-142-1"></div>
            <img className="vector-31-1" src={vector312} alt="Vector 31" />
            <div className="rectangle-141-2"></div>
          </div> */}


        </div>
        <div className="overlap-group-container">


            {/* Past leed rating section */}
          {/* <div className="group-container">
            <div className="group-1000006383" style={{ backgroundImage: `url(${group1000006383})` }}>
              <div className="subtract-container">
                <img className="subtract" src={subtract1} alt="Subtract" />
                <img className="subtract" src={subtract2} alt="Subtract" />
                <img className="subtract-1" src={subtract3} alt="Subtract" />
                <img className="subtract-2" src={subtract4} alt="Subtract" />
              </div>
            </div>
            <div className="overlap-group1-1">
              <div className="lead-rating foundersgrotesk-light-black-20-2px">
                <span className="foundersgrotesk-light-black-20-2px">LEED Rating</span>
              </div>
              <div className="text-1 foundersgrotesk-regular-normal-black-20-2px">
                <span className="foundersgrotesk-regular-normal-black-20-2px">{spanText16}</span>
              </div>
            </div>
          </div>
          <div className="overlap-group23">
            <p className="lorem-ipsum-dolor-si foundersgrotesk-regular-normal-shark-20-9px">
                          <span className="foundersgrotesk-regular-normal-shark-20-9px">With a rating of 50, this project would just barely qualify for a Silver LEED rating.There is much room for improvement to achieve a higher rating.It is recommended to reassess the project's sustainability goals and objectives to ensure they are aligned with the LEED rating system. In addition, conduct a thorough review of the LEED scorecard and identify the areas where the project is losing points. </span>
            </p>
          </div> */}
        </div>
        <div className="overlap-group22">
            <div className="leed-start-now">LEED Calculator</div>
            <Link to="/Calculator" className="start-now">Start Now</Link>
              <div className="start-arrow">
            <img src='./arrow.png' alt="Arrow" />
          </div>
      </div>

      
        <div className="overlap-group19">
          <div className="rectangle-176"></div>
          <div className="ellipse-81"></div>
          <img className="line-785" src={line785} alt="Line 785" />
          <img className="line-786" src={line786} alt="Line 786" />
          <div className="rectangle-179"></div>
          <img className="line-787" src={line787} alt="Line 787" />
          <img className="line-788" src={line788} alt="Line 788" />
          <div className="ellipse-82"></div>
          <div className="ellipse-83"></div>
          <div className="ellipse-86"></div>
          <div className="ellipse-87"></div>
          <img className="line-789" src={line789} alt="Line 789" />
          <div className="ellipse-84"></div>
          <div className="ellipse-85"></div>
          <img className="line-790" src={line790} alt="Line 790" />

          <div className="overlap-group17">
            <div className="want-to-know-more foundersgrotesk-regular-normal-white-38px">
              <span className="foundersgrotesk-regular-normal-black-38px">{spanText20}</span>
            </div>
            <div className="group-1000006588">
              <div className="flex-col">
                <div className="join-the-waitlist foundersgrotesk-regular-normal-blueberry-27px">
                  <span className="foundersgrotesk-regular-normal-portage-27px">{spanText21}</span>
                  <span className="foundersgrotesk-regular-normal-blueberry-27px-2">{spanText22}</span>
                </div>
                <div className="enter-your-email-address foundersgrotesk-regular-normal-pigeon-post-20px">
                  <input
                    type="email"
                    className="email-input foundersgrotesk-regular-normal-pigeon-post-20px"
                    placeholder="email address"
                  />
                  {/* <span className="foundersgrotesk-regular-normal-pigeon-post-20px">{spanText23}</span> */}
                </div>
              </div>
              <div className="overlap-group-2">
                <div className="submit foundersgrotesk-regular-normal-white-20px">
                  <span className="foundersgrotesk-regular-normal-white-20px">{spanText24}</span>
                </div>
              </div>
            </div>
          </div>


        </div>


        <Frame613 group1000006420={frame613Props.group1000006420} />
      </div>
    </div>
  );
}

export default LEEDSCalculator;
