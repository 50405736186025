import React from "react";
import Group1000006462 from "../Group1000006462";
import "./Group1000006470.css";

function Group1000006470(props) {
  const {
    group1000006459,
    group10000064621Props,
    group10000064622Props,
    group10000064623Props,
    group10000064624Props,
  } = props;

  return (
    <div className="group-1000006470-2">
      <div className="overlap-group7-2">
        <div className="frame-20 sfprodisplay-regular-normal-silver-12-9px">
          <div className="group-1000006463">
            <div className="number-2">
              <span className="sfprodisplay-regular-normal-silver-12-9px">100</span>
            </div>
            <img className="line" src="/img/line-28-1@2x.png" alt="Line 27" />
          </div>
          <Group1000006462 spanText={group10000064621Props.spanText} />
          <Group1000006462 spanText={group10000064622Props.spanText} />
          <Group1000006462 spanText={group10000064623Props.spanText} />
          <Group1000006462 spanText={group10000064624Props.spanText} />
          <div className="group-1000006465">
            <div className="number-1">
              <span className="sfprodisplay-regular-normal-silver-12-9px">0</span>
            </div>
            <img className="line" src="/img/line-28-1@2x.png" alt="Line 32" />
          </div>
        </div>
        <img className="group-1000006459" src={group1000006459} alt="Group 1000006459" />
      </div>
      <div className="frame-21 sfprodisplay-regular-normal-star-dust-12-9px">
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">0</span>
        </div>
        <div className="number-3">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">1</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">2</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">3</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">4</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">5</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">6</span>
        </div>
        <div className="number-1">
          <span className="sfprodisplay-regular-normal-star-dust-12-9px">7</span>
        </div>
      </div>
    </div>
  );
}

export default Group1000006470;
