import React from "react";
import "./Group1000006549.css";

function Group1000006549() {
  return (
    <div className="group-1000006549">
      <p className="the-easiest-design-p foundersgrotesk-regular-normal-white-26px">
        <span className="foundersgrotesk-regular-normal-white-26px">The Easiest Design Platform Ever Created</span>
      </p>
      <img className="line-15-1" src="/img/line-15-1@2x.png" alt="Line 15" />
      <p className="although-the-world-s foundersgrotesk-regular-normal-white-22px">
        <span className="foundersgrotesk-regular-normal-white-22px">
        Although the world strives for sustainable building, they require more forethought and planning than a less sustainable design. With our product, you can create entire construction designs and system plans in a sustainable way. All you need to do is input your desired specifications, and let us do the rest of the work.
        </span>
      </p>
    </div>
  );
}

export default Group1000006549;
