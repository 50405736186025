import React from "react";
import "./Group1000006462.css";

function Group1000006462(props) {
  const { spanText } = props;

  return (
    <div className="group-100000646">
      <div className="number-4">
        <span className="sfprodisplay-regular-normal-silver-12-9px">{spanText}</span>
      </div>
      <img className="line-1" src="/img/line-28-1@2x.png" alt="Line 28" />
    </div>
  );
}

export default Group1000006462;
