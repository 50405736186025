import React from "react";
import "./Frame613.css";

function Frame613(props) {
  const { group1000006420, className } = props;

  return (
    <div className={`frame-61 ${className || ""}`}>
      <img className="line-740" src="/img/line-740-1@2x.png" alt="Line 740" />
      <img className="group-1000006571" src="/img/group-1000006571-1@2x.png" alt="Group 1000006571" />
      <div className="overlap-group-container-1">
        <div className="overlap-group2-1">
          <div className="group-container-3">
            <div className="overlap-group-5">
              <img className="vector-24-2" src="/img/vector-24-2@2x.png" alt="Vector 24" />
              <div className="rectangle-95-2"></div>
              <img className="vector-25-2" src="/img/vector-24-2@2x.png" alt="Vector 25" />
              <img className="vector-23-2" src="/img/vector-23-2@2x.png" alt="Vector 23" />
              <div className="rectangle-96-2"></div>
            </div>
            <img className="group-1000006420-2" src={group1000006420} alt="Group 1000006420" />
          </div>
          <div className="rectangle-162"></div>
          <img className="vector-36" src="/img/vector-36-1@2x.png" alt="Vector 36" />
        </div>
        <div className="overlap-group-6">
          <p className="an-ai-solution-to-ma foundersgrotesk-regular-normal-white-20px">
            <span className="spanlzkom foundersgrotesk-regular-normal-white-20px">
              An AI solution to make the architectural planning process more efficient, affordable, and sustainable.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Frame613;
