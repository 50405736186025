import React from 'react';
import './Circle.css';


const Circle = ({ message, style }) => {
    return (
        <div className = "circle" style={style}>
            <span className = "message"> {message}</span>
        </div>
    );
};

export default Circle;