import React from "react";
import "./Group1000006440.css";

function Group1000006440(props) {
  const { group1000006406 } = props;

  return (
    <div className="group-1000006440">
      {/*<div className="overlap-group3">
        <p className="weve-trained-a-mode foundersgrotesk-regular-normal-black-19-5px">
          <span className="foundersgrotesk-regular-normal-black-19-5px">
            We’ve trained a model called ChatGPT which interacts in a conversational way. The dialogue format makes it
            possible for ChatGPT to answer follow-up questions, admit its mistakes, challenge incorrect premises, and
            reject inappropriate requests. We’ve trained a model called ChatGPT which interacts in a conversational way.
            The dialogue format makes it possible for ChatGPT to answer follow-up questions, admit its mistakes,
            challenge incorrect premises, and reject inappropriate requests.We’ve trained a model called ChatGPT which
            interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer follow-up
            questions, admit its mistakes, challenge incorrect premises, and reject inappropriate requests.
          </span>
        </p>
        <img className="line-2" src="/img/line-2-1@2x.png" alt="Line 2" />
        <img className="group-1000006406" src={group1000006406} alt="Group 1000006406" />
      </div>*/}
    </div>
  );
}

export default Group1000006440;
