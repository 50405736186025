import React from "react";
import "./Frame61.css";
import { Link } from 'react-router-dom';

function Frame61(props) {
  const { group1000006420, spanText7 } = props;

  return (
    <div className="frame-61-2">
      <div className="group-container-27">
        <div className="overlap-group2-4">
          <div className="group-container-28">
            <div className="overlap-group-15">
              <img className="vector-24-5" src="/img/vector-24-1@2x.png" alt="Vector 24" />
              <img className="rectangle-95-5" src="/img/rectangle-95-1@2x.png" alt="Rectangle 95" />
              <img className="vector-25-5" src="/img/vector-25-1@2x.png" alt="Vector 25" />
              <img className="vector-23-5" src="/img/vector-23-1@2x.png" alt="Vector 23" />
              <div className="rectangle-96-5"></div>
            </div>
            <img className="group-1000006420-5" src={group1000006420} alt="Group 1000006420" />
          </div>
          <div className="rectangle-162-2"></div>
        </div>
        <div className="group-1000006570">
          <div className="overlap-group-16">
            <p className="an-ai-solution-to-ma-3 foundersgrotesk-regular-normal-white-20px">
              <span className="foundersgrotesk-regular-normal-white-20px">
                An AI solution to make the architectural planning process more efficient, affordable, and sustainable.
              </span>
            </p>
          </div>
          <img className="line-739" src="/img/line-739-1@2x.png" alt="Line 739" />
        </div>
        <div className="group-1000006403">
          <div className="join-the-waitlist-2 foundersgrotesk-regular-normal-desert-storm-20px">
          <Link to="/sign-up" className="link-class foundersgrotesk-regular-normal-desert-storm-20px">
               Join the waitlist
              </Link> 
            {/* <span className="foundersgrotesk-regular-normal-desert-storm-20px">Join the waitlist</span> */}
          </div>
          <div className="overlap-group-17">
            <div className="sign-up-3 foundersgrotesk-regular-normal-ship-gray-20px-2">
            <Link to="/sign-up" className="foundersgrotesk-regular-normal-white-20px">
               Sign Up
              </Link> 
            </div>
          </div>
        </div>
      </div>
      <img className="line-19" src="/img/line-19-1@2x.png" alt="Line 19" />
      <div className="group-1000006567">
        <div className="group-1000006563">
          <div className="overlap-group1-3">
            <img className="rectangle-66" src="/img/rectangle-66-1@2x.png" alt="Rectangle 66" />
            <img className="line-736" src="/img/line-736-1@2x.png" alt="Line 736" />
            <div className="frame-62 foundersgrotesk-regular-normal-white-20px">
              <div className="company-3">
              <Link to="/landing" className="link-class foundersgrotesk-regular-normal-white-20px">
               Company
              </Link> 
              </div>
              <div className="product-3">
                <span className="foundersgrotesk-regular-normal-white-20px">Product</span>
              </div>
              <div className="place-3">
              <Link to="/sign-up" className="link-class foundersgrotesk-regular-normal-white-20px">
               Research
              </Link> 
              </div>
            </div>
          </div>
          <div className="overlap-group-18 foundersgrotesk-regular-normal-white-20px">
            <div className="leed-calculator-2">
            <Link to="/leeds-calculator" className="link-class foundersgrotesk-regular-normal-white-20px">
               LEED Calculator
              </Link>             </div>
            <div className="ai-product-2">
            <Link to="/ai-product" className="link-class foundersgrotesk-regular-normal-white-20px">
               AI Product
              </Link> 
            </div>
          </div>
        </div>
        <div className="group-1000006564 foundersgrotesk-regular-normal-white-22-1px">
          <div className="terms">
            <span className="foundersgrotesk-regular-normal-white-22-1px">Terms</span>
          </div>
          <div className="privacy-policy">
            <span className="foundersgrotesk-regular-normal-white-22-1px">Privacy Policy</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame61;
