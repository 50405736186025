import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import LEEDSCalculator from "./components/LEEDSCalculator";
import AIProduct from "./components/AIProduct";
import Landing from "./components/Landing";
import SignInPage from "./components/SignInPage";
import Calculator from "./components/Calculator";
import ComingSoon from "./components/ComingSoon";

function App() {
    let signInPageData;
    let calcPageData;
    return (
    <Router>
      <Switch>
        <Route path="/leeds-calculator">
          <LEEDSCalculator {...lEEDSCalculatorData} />
        </Route>
        <Route path="/ai-product">
          <AIProduct {...aIProductData} />
        </Route>
        <Route path="/:path(|landing)">
          {/* <Landing {...landingData} /> */}
          <ComingSoon />
        </Route>
          <Route path = "/sign-up">
              < SignInPage {...signInPageData} />
          </Route>
          <Route path = "/calculator">
            < Calculator {...calcPageData} />
          </Route>
      </Switch>
    </Router>
  );
}

export default App;

const group100000647031Data = {
    vector24: "/img/vector-24-2@2x.png",
};

const group1000006440Data = {
    group1000006406: "/img/group-1000006406-1@2x.png",
};

const frame1002Data = {
    className: "frame-101",
};

const frame6131Data = {
    group1000006420: "/img/group-1000006420-2@2x.png",
};

const lEEDSCalculatorData = {
    overContainer: "/img/group-1000006558-1@2x.png",
    spanText1: "LEED Calculator",
    spanText2: "Discover the power of sustainability with our innovative LEED rating calculator, which illuminates the true environmental impact of your building and sets you on the path towards a more energy-efficient future",
    vector17: "/img/vector-17-2@2x.png",
    spanText3: "waste management",
    spanText4: "energy consumption",
    polygon1: "/img/polygon-1-1@2x.png",
    spanText5: "water usage",
    polygon2: "/img/polygon-2-1@2x.png",
    overlapGroup8: "/img/rectangle-22-1@2x.png",
    spanText6: "carbon emission",
    vector311: "/img/vector-31-1@2x.png",
    spanText7: "LEED Calculator",
    vector11: "/img/vector-11-1@2x.png",
    spanText8: "energy bills",
    spanText9: "waste management",
    spanText10: "gas consumption",
    spanText11: "functional unit",
    spanText12: "building automation",
    spanText13: "renewable energy",
    spanText14: "Create",
    line70: "/img/line-71-1@2x.png",
    line71: "/img/line-71-1@2x.png",
    vector312: "/img/vector-31-2@2x.png",
    group1000006383: "/img/subtract-1@2x.png",
    subtract1: "/img/subtract-2@2x.png",
    subtract2: "/img/subtract-3@2x.png",
    subtract3: "/img/subtract-4@2x.png",
    subtract4: "/img/subtract-5@2x.png",
    spanText15: "Lead Rating",
    spanText16: "<50",
    spanText17: <React.Fragment>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</React.Fragment>,
    spanText18: "Tips to improve",
    spanText19: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    line785: "/img/line-785-1@2x.png",
    line786: "/img/line-786-1@2x.png",
    line787: "/img/line-787-1@2x.png",
    line788: "/img/line-788-1@2x.png",
    line789: "/img/line-789-1@2x.png",
    line790: "/img/line-790-1@2x.png",
    spanText20: "Want to know more?",
    spanText21: <React.Fragment><br /></React.Fragment>,
    spanText22: "Join the waitlist.",
    spanText23: "enter your email address...",
    spanText24: "submit",
    group10000064703Props: group100000647031Data,
    group1000006440Props: group1000006440Data,
    frame100Props: frame1002Data,
    frame613Props: frame6131Data,
};

const group100000647032Data = {
    vector24: "/img/vector-24-2@2x.png",
};

const frame6132Data = {
    group1000006420: "/img/group-1000006420-2@2x.png",
    className: "frame-61-1",
};

const aIProductData = {
    group1000006558: "/img/group-1000006558-1@2x.png",
    spanText1: "this revolutionary generative AI effortlessly generates construction-ready architectural blueprints with unparalleled precision and speed.",
    spanText2: "AI Product",
    line2: "/img/line-2-1@2x.png",
    group1000006406: "/img/group-1000006406-1@2x.png",
    spanText3: "We’ve trained a model called ChatGPT which interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer follow-up questions, admit its mistakes, challenge incorrect premises, and reject inappropriate requests. We’ve trained a model called ChatGPT which interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer follow-up questions, admit its mistakes, challenge incorrect premises, and reject inappropriate requests.We’ve trained a model called ChatGPT which interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer follow-up questions, admit its mistakes, challenge incorrect premises, and reject inappropriate requests.",
    line785: "/img/line-785-1@2x.png",
    line786: "/img/line-786-1@2x.png",
    line787: "/img/line-787-1@2x.png",
    line788: "/img/line-788-1@2x.png",
    line789: "/img/line-789-1@2x.png",
    line790: "/img/line-790-1@2x.png",
    spanText4: "Want to know more?",
    spanText5: <React.Fragment><br /></React.Fragment>,
    spanText6: "Join the waitlist.",
    spanText7: "enter your email address...",
    spanText8: "submit",
    group10000064703Props: group100000647032Data,
    frame613Props: frame6132Data,
};

const group10000064621Data = {
    spanText: "80",
};

const group10000064622Data = {
    spanText: "60",
};

const group10000064623Data = {
    spanText: "40",
};

const group10000064624Data = {
    spanText: "20",
};

const group1000006470Data = {
    group1000006459: "/img/group-1000006459-1@2x.png",
    group10000064621Props: group10000064621Data,
    group10000064622Props: group10000064622Data,
    group10000064623Props: group10000064623Data,
    group10000064624Props: group10000064624Data,
};

const group10000065921Data = {
    spanText1: "Jake Martin",
    spanText2: "Developer",
};

const group10000065922Data = {
    spanText1: "Jane Sun",
    spanText2: "Developer",
};

const group10000065923Data = {
    spanText1: "Roman Ushakov",
    spanText2: "Developer",
};

const group10000065924Data = {
    spanText1: "Ziare Rene",
    spanText2: "Developer",
};

const group10000065925Data = {
    spanText1: "Reagan Meek",
    spanText2: "CTO",
};

const group10000065926Data = {
    spanText1: "Mandika Swartz",
    spanText2: "COO",
};

const group10000065927Data = {
    spanText1: "JC Arce",
    spanText2: "CEO",
    className: "group-1000006593",
};

const group10000065928Data = {
    spanText1: "JC Arce",
    spanText2: "CEO",
    className: "group-1000006593",
};

const group10000065929Data = {
    spanText1: "Roy Glick",
    spanText2: "CPO",
    className: "group-1000006593",
};

const group100000659210Data = {
    spanText1: "Roy Glick",
    spanText2: "CPO",
    className: "group-1000006593",
};

const group100000659211Data = {
    spanText1: "Lily Kushnarova",
    spanText2: "Developer",
    className: "group-1000006593",
};

const group100000659212Data = {
    spanText1: "Lily Kushnarova",
    spanText2: "Developer",
    className: "group-1000006593",
};

const group100000659213Data = {
    spanText1: "Mandika Swartz",
    spanText2: "COO",
};

const group100000659214Data = {
    spanText1: "Kyle Evenson",
    spanText2: "Developer",
};

const group100000659215Data = {
    spanText1: "Kyle Evenson",
    spanText2: "Developer",
};

const group100000659216Data = {
    spanText1: "Reagan Meek",
    spanText2: "CTO",
};

const group100000659217Data = {
    spanText1: "Luke Walsh",
    spanText2: "Developer",
};

const group100000659218Data = {
    spanText1: "Luke Walsh",
    spanText2: "Developer",
};

const frame61Data = {
    group1000006420: "/img/group-1000006420-1@2x.png",
    spanText7: "LEED Calculator",
};

const landingData = {
    mesh21: "/img/mesh2-1-1@2x.png",
    spanText1: "Enhancing ",
    spanText2: "design",
    spanText3: " for Architects",
    spanText4: "An AI solution to make the architectural planning process more efficient, affordable, and sustainable.",
    spanText5: "Join the Beta",
    arrow1: "/img/arrow-1-1@2x.png",
    spanText6: "Learn more",
    arrow2: "/img/arrow-2-1@2x.png",
    vector24: "/img/vector-24-2@2x.png",
    rectangle95: "/img/rectangle-95-2@2x.png",
    vector25: "/img/vector-25-2@2x.png",
    vector23: "/img/vector-23-2@2x.png",
    group1000006420: "/img/group-1000006420-1@2x.png",
    spanText7: "Company",
    spanText8: "Product",
    vector34: "/img/vector-34-1@2x.png",
    spanText9: "Research",
    spanText10: "Sign Up",
    spanText11: "Explore More",
    spanText12: "Energy Savings",
    spanText13: "Explore More",
    spanText14: "Design Accessibility",
    ellipse65: "/img/ellipse-65-1@2x.png",
    vector38: "/img/vector-39-1@2x.png",
    vector39: "/img/vector-39-1@2x.png",
    vector40: "/img/vector-40-1@2x.png",
    vector41: "/img/vector-41-1@2x.png",
    vector42: "/img/vector-42-1@2x.png",
    group1000006580: "/img/group-1000006581-1@2x.png",
    group1000006581: "/img/group-1000006581-1@2x.png",
    group1000006582: "/img/group-1000006582-1@2x.png",
    group1000006583: "/img/group-1000006582-1@2x.png",
    line758: "/img/line-758-1@2x.png",
    line760: "/img/line-762-1@2x.png",
    line761: "/img/line-762-1@2x.png",
    line762: "/img/line-762-1@2x.png",
    line763: "/img/line-763-1@2x.png",
    line764: "/img/line-764-1@2x.png",
    line765: "/img/line-764-1@2x.png",
    line766: "/img/line-767-1@2x.png",
    line767: "/img/line-767-1@2x.png",
    line768: "/img/line-767-1@2x.png",
    line769: "/img/line-769-1@2x.png",
    line771: "/img/line-769-1@2x.png",
    line770: "/img/line-770-1@2x.png",
    line759: "/img/line-759-1@2x.png",
    spanText15: "002",
    vector43: "/img/vector-43-1@2x.png",
    group1000006478: "/img/group-1000006478-1@2x.png",
    spanText16: "Save millions of dollars on construction fees through environmental savings.",
    spanText17: "Learn more",
    spanText18: "BT.ai has saved two trillions acres of forests in the past year of 2023 alone.",
    spanText19: "Here’s how we did it",
    subtract1: "/img/subtract-1@2x.png",
    subtract2: "/img/subtract-2@2x.png",
    spanText20: "Take a glance into our language models and our success rate.",
    spanText21: "Take a peek",
    group1000006547: "/img/vector-16-1@2x.png",
    spanText22: "Who We Are",
    spanText23: "Founded at USC, we are a group of highly skilled individuals dedicated to revolutionizing the architectural industry by creating an AI that can generate construction-ready architectural blueprints. Our team comprises experts from various backgrounds, including architecture, computer science, and engineering, all with the same goal in mind - to make the architectural process more efficient, affordable, and sustainable.",
    line735: "/img/line-735-1@2x.png",
    spanText24: "Meet The Team",
    group9: "/img/group-9-1@2x.png",
    group1000006470Props: group1000006470Data,
    group10000065921Props: group10000065921Data,
    group10000065922Props: group10000065922Data,
    group10000065923Props: group10000065923Data,
    group10000065924Props: group10000065924Data,
    group10000065925Props: group10000065925Data,
    group10000065926Props: group10000065926Data,
    group10000065927Props: group10000065927Data,
    group10000065928Props: group10000065928Data,
    group10000065929Props: group10000065929Data,
    group100000659210Props: group100000659210Data,
    group100000659211Props: group100000659211Data,
    group100000659212Props: group100000659212Data,
    group100000659213Props: group100000659213Data,
    group100000659214Props: group100000659214Data,
    group100000659215Props: group100000659215Data,
    group100000659216Props: group100000659216Data,
    group100000659217Props: group100000659217Data,
    group100000659218Props: group100000659218Data,
    frame61Props: frame61Data,
};

