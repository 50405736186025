import React, { useState } from "react";
import "./Group10000064703.css";
import { Link } from 'react-router-dom';

function Group10000064703(props) {
    const { vector24 } = props;

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="group-1000006470">
            <div className="overlap-group14 foundersgrotesk-regular-normal-ship-gray-18px">
                <Link to="/landing">
                <div className="group-container-1">
                        <img className="group-1000006420 green-logo" src={require("./GREENLogo.png")} alt="Group 1000006420" />
                </div>

                </Link>
                <div className="company">
                {/* <Link to="/landing" className="foundersgrotesk-regular-normal-ship-gray-18px">
                    Company
                  </Link>     */}
                  <Link to="/landing" className="foundersgrotesk-regular-normal-ship-gray-18px" style={{ color: 'green' }}>
                         Company
                    </Link>

                </div>
                <div className="group-1000006484">
                    <div className="product" onClick={toggleDropdown}>

                        <span className="foundersgrotesk-regular-normal-ship-gray-18px" style={{color: 'green'}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Product &nbsp;</span>
                        <img className="vector-34" src="/img/vector-34-1@2x.png" alt="Vector 34" />
                        {showDropdown && (
                            <div className="dropdown">
                                <ul>
                                    <li>
                                        <Link to ="/ai-product" style={{color: 'green'}}>AI Product</Link>

                                    </li>
                                    <li>
                                    <Link to="/leeds-calculator" style={{color: 'green'}}>Green</Link>

                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="place">
                <Link to="/sign-up" className="foundersgrotesk-regular-normal-ship-gray-18px" style={{color: 'green'}}>
                    Research
                  </Link> 
                    {/* <span className="foundersgrotesk-regular-normal-ship-gray-18px">Research</span> */}
                </div>
                <div className="frame-11">
                    <div className="sign-up">
                      <Link to="/sign-up" className="foundersgrotesk-regular-normal-ship-gray-18px" style={{color: 'green'}}>
                         Sign Up
                      </Link> 
                        {/* <span className="foundersgrotesk-regular-normal-ship-gray-18px">Sign Up</span> */}
                    </div>
                </div>
            </div>
        </div>
    );
    // things to change back to normal navbar: green font, sign up box outline color and size back to 1px, 
}

export default Group10000064703;



